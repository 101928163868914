import * as React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div<{ bgColor: string }>`
  flex: 0 0 auto;
  width: 180px;
  height: 180px;
  background-color: ${({ bgColor }) => bgColor};
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  display: flex;
  align-items: flex-end;
  margin: 10px;
`

const Infos = styled.span`
  background-color: #fff;
  padding: 16px 10px;
  flex: 1;
`

const ColorName = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: #111;
`

const ColorHumanName = styled.span`
  font-size: 12px;
  font-style: italic;
  color: #111;
`

const ColorCode = styled.span`
  font-size: 10px;
  color: #999;
`

type ColorBlockProps = {
  hexa: string
  humanName?: string
  name: string
}

export function ColorBlock({ hexa, humanName = '', name }: ColorBlockProps): React.ReactElement<ColorBlockProps> {
  return (
    <Wrapper bgColor={hexa}>
      <Infos>
        <ColorName>{name}</ColorName>
        <br />
        <ColorHumanName>{humanName}</ColorHumanName>
        <br />
        <ColorCode>{hexa}</ColorCode>
      </Infos>
    </Wrapper>
  )
}
