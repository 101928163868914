import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { COLORS } from '@ulule/owl-kit-components/dist/next.esm';
import { Layout } from '../../layouts/Layout';
import { ColorBlock } from '../../components/ColorBlock';
import { CodeExample } from '../../components/CodeExample';
import { SEO } from '../../components/SEO';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Identity > Colors" mdxType="SEO" />
    <h1>{`Colors`}</h1>
    <h2>{`Primary colors`}</h2>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px',
      paddingBottom: '24px'
    }}>
  <ColorBlock name="PRIMARY_BLUE" humanName="Azure Blue" hexa={COLORS.PRIMARY_BLUE} mdxType="ColorBlock" />
  <ColorBlock name="PRIMARY_BLUE_HOVER" humanName="Dim Azure Blue" hexa={COLORS.PRIMARY_BLUE_HOVER} mdxType="ColorBlock" />
  <ColorBlock name="PRIMARY_RED" humanName="Grenadine Red" hexa={COLORS.PRIMARY_RED} mdxType="ColorBlock" />
  <ColorBlock name="PRIMARY_RED_HOVER" humanName="Dim Grenadine Red" hexa={COLORS.PRIMARY_RED_HOVER} mdxType="ColorBlock" />
  <ColorBlock name="PRIMARY_BLACK" hexa={COLORS.PRIMARY_BLACK} mdxType="ColorBlock" />
  <ColorBlock name="PRIMARY_BLACK_HOVER" hexa={COLORS.PRIMARY_BLACK_HOVER} mdxType="ColorBlock" />
  <ColorBlock name="PRIMARY_SAND" humanName="Sand" hexa={COLORS.PRIMARY_SAND} mdxType="ColorBlock" />
  <ColorBlock name="PRIMARY_WHITE" humanName="White" hexa={COLORS.PRIMARY_WHITE} mdxType="ColorBlock" />
    </div>
    <h2>{`Shades of blue`}</h2>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px',
      paddingBottom: '24px'
    }}>
  <ColorBlock name="BLUE_SHADE_1" humanName="Pastel Blue" hexa={COLORS.BLUE_SHADE_1} mdxType="ColorBlock" />
  <ColorBlock name="BLUE_SHADE_2" humanName="Petrol Blue" hexa={COLORS.BLUE_SHADE_2} mdxType="ColorBlock" />
  <ColorBlock name="BLUE_SHADE_3" humanName="" hexa={COLORS.BLUE_SHADE_3} mdxType="ColorBlock" />
    </div>
    <h2>{`Shades of red`}</h2>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px',
      paddingBottom: '24px'
    }}>
  <ColorBlock name="RED_SHADE_1" humanName="Pastel Red" hexa={COLORS.RED_SHADE_1} mdxType="ColorBlock" />
  <ColorBlock name="RED_SHADE_2" humanName="Bordeaux" hexa={COLORS.RED_SHADE_2} mdxType="ColorBlock" />
    </div>
    <h2>{`Shades of grey`}</h2>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px',
      paddingBottom: '24px'
    }}>
  <ColorBlock name="GREY_SHADE_1" hexa={COLORS.GREY_SHADE_1} mdxType="ColorBlock" />
  <ColorBlock name="GREY_SHADE_2" hexa={COLORS.GREY_SHADE_2} mdxType="ColorBlock" />
  <ColorBlock name="GREY_SHADE_3" hexa={COLORS.GREY_SHADE_3} mdxType="ColorBlock" />
  <ColorBlock name="GREY_SHADE_4" hexa={COLORS.GREY_SHADE_4} mdxType="ColorBlock" />
  <ColorBlock name="GREY_SHADE_5" hexa={COLORS.GREY_SHADE_5} mdxType="ColorBlock" />
  <ColorBlock name="GREY_SHADE_6" hexa={COLORS.GREY_SHADE_6} mdxType="ColorBlock" />
  <ColorBlock name="GREY_SHADE_7" hexa={COLORS.GREY_SHADE_7} mdxType="ColorBlock" />
    </div>
    <h2>{`Brand accent colors`}</h2>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px',
      paddingBottom: '24px'
    }}>
  <ColorBlock name="BRAND_ACCENT_GREEN" hexa={COLORS.BRAND_ACCENT_GREEN} mdxType="ColorBlock" />
  <ColorBlock name="BRAND_ACCENT_PURPLE" hexa={COLORS.BRAND_ACCENT_PURPLE} mdxType="ColorBlock" />
  <ColorBlock name="BRAND_ACCENT_YELLOW" hexa={COLORS.BRAND_ACCENT_YELLOW} mdxType="ColorBlock" />
    </div>
    <h2>{`Social colors`}</h2>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px',
      paddingBottom: '24px'
    }}>
  <ColorBlock name="FACEBOOK" hexa={COLORS.FACEBOOK} mdxType="ColorBlock" />
  <ColorBlock name="MESSENGER" hexa={COLORS.MESSENGER} mdxType="ColorBlock" />
  <ColorBlock name="TWITTER" hexa={COLORS.TWITTER} mdxType="ColorBlock" />
  <ColorBlock name="LINKEDIN" hexa={COLORS.LINKEDIN} mdxType="ColorBlock" />
  <ColorBlock name="YOUTUBE" hexa={COLORS.YOUTUBE} mdxType="ColorBlock" />
    </div>
    <h2>{`Deprecated Primary colors`}</h2>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px',
      paddingBottom: '24px'
    }}>
  <ColorBlock name="PRIMARY_GREEN" hexa={COLORS.PRIMARY_GREEN} mdxType="ColorBlock" />
  <ColorBlock name="PRIMARY_GREEN_HOVER" hexa={COLORS.PRIMARY_GREEN_HOVER} mdxType="ColorBlock" />
    </div>
    <h2>{`Deprecated Secondary colors`}</h2>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px',
      paddingBottom: '24px'
    }}>
  <ColorBlock name="SECONDARY_YELLOW" hexa={COLORS.SECONDARY_YELLOW} mdxType="ColorBlock" />
  <ColorBlock name="SECONDARY_INDIGO" hexa={COLORS.SECONDARY_INDIGO} mdxType="ColorBlock" />
  <ColorBlock name="SECONDARY_PURPLE" hexa={COLORS.SECONDARY_PURPLE} mdxType="ColorBlock" />
  <ColorBlock name="SECONDARY_RED" hexa={COLORS.SECONDARY_RED} mdxType="ColorBlock" />
  <ColorBlock name="SECONDARY_PINK" hexa={COLORS.SECONDARY_PINK} mdxType="ColorBlock" />
    </div>
    <h2>{`How to use it?`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// First import COLORS constant from library
import { COLORS } from '@ulule/owl-kit-components/dist/next.esm'

// Then access of the color with the name of the colors as key
const myColor = COLORS['PRIMARY_BLUE']
`}</code></pre>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      